
import React, { useEffect, useState } from 'react';
import App from '../App';
import IubendaLoader from './IubendaLoader';
import GTMLoader from './GTMLoader';

const gtmId = 'GTM-P87JWK9';
const iubendaScripts = [
    'https://cdn.iubenda.com/cs/iubenda_cs.js',
    'https://cdn.iubenda.com/iubenda.js'
];

const RootComponent = () => {
    const isSnap = navigator && navigator.userAgent === 'ReactSnap';
    const [isIubendaLoaded, setIsIubendaLoaded] = useState(false);
    const [isDefaultConsentSet, setDefaultConsentSet] = useState(false);

    // Imposta il consenso predefinito prima di caricare GTM
    useEffect(() => {
        //console.log('Configurazione del consenso predefinito...');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'default_consent',
            defaultConsent: {
                ad_storage: 'denied',
                analytics_storage: 'denied',
                functionality_storage: 'granted',
                personalization_storage: 'denied',
                security_storage: 'granted',
            },
        });
        setDefaultConsentSet(true);
        //console.log('Consenso predefinito configurato.');
    }, []);


    return (
        <>
            <App />
            {!isSnap ? <IubendaLoader scripts={iubendaScripts} onLoaded={() => setIsIubendaLoaded(true)} /> : null}
            {!isSnap && isIubendaLoaded && isDefaultConsentSet ? <GTMLoader gtmId={gtmId} defaultConsentSet={isDefaultConsentSet} /> : null}
        </>
    );
};

export default RootComponent;